import React, { FC } from "react"
import { PageProps } from "gatsby"
import { Typography } from "@material-ui/core"
import Layout from "../components/layout"

const DatenschutzPage: FC<PageProps> = () => {
  return (
    <Layout>
      <Typography variant="h4">Datenschutzerklärung</Typography>
    </Layout>
  )
}

export default DatenschutzPage
